.career-hero-img{
  //background: url(https://images.unsplash.com/photo-1633910065799-d1d0b3ed96e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1941&q=80);
  background: url('../Assets/Images/Career/career-coverd-photo.jpg');
  //height: 42em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100vh;
  //height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  //margin-bottom: 100px;
  @media (max-width: 1024px) {
    //height: 16em;
    height: 100vh;
  }
}
.career-person-work {
  max-width: 1400px;
  width: 100%;
  padding: 100px 50px 0 50px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 10px 15px;
  }

  .career-person-work-inner {
    display: flex;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto 0;
    padding: 0 70px 0;
    justify-content: space-around;
    column-gap: 30px;
    align-items: center;
    @media (max-width: 900px) {
      flex-wrap: wrap;
    }
    @media (max-width: 768px) {
      padding: 50px 0 0;
    }

    .career-person-left-txt {
      text-align: center;
      h3 {
        max-width: 400px;
        font-size: 40px;
        font-weight: 600;
        font-family: $mainfont;
        margin: 0 auto;
      }

      p {
        color: #14142B;
        line-height: 1.5em;
        font-size: $fontsize_14;
        margin-bottom: 2em;
        margin-top: 17px;
      }

      .info-button {
        background-color: #97C63D;
        color: #FFFFFF;
      }

      .info-button:focus {
        outline: none;
        box-shadow: none;
      }
    }

  }

  .careerPhoto-men {
    max-width: 518px;

    img {
      @media (max-width: 1300px) {
        width: 340px;
      }
    }
  }
}


.careerExp-main {
  background-color: #F8F8F8;

  .career-setion-Exp {
    display: flex;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto 0;
    padding: 100px 40px;
    justify-content: space-around;
    column-gap: 30px;
    align-items: center;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin: 0 auto 0;
      padding: 50px 15px;

    }

    .secTwo-info {
      max-width: 600px;
      width: 100%;

      h3 {
        font-size: 40px;
        font-weight: 600;
        font-family: $mainfont;
        margin-bottom: 30px;
      }

      p {
        color: #14142B;
        line-height: 1.5em;
        font-size: $fontsize_14;
        margin-bottom: 2em;
        font-weight: bold;
      }

    }

    .careerPhoto {
      max-width: 600px;
      width: 100%;

      img {
        max-width: 540px;
        width: 100%;
        // padding-left: 100px;
      }
    }

    .career-icon-main {
      display: flex;
      justify-content: space-around;

      .career-exp-small-ico {

      }

      img {
        // height: 25%;
        width: 80px;
      }

      h5 {
        margin-top: 1rem;
        text-align: center;
      }
    }
  }

}

.careerValues-main {
  .our-values-accordion {
    //display: flex;
    margin: 0 auto;
    max-width: 1820px;
    width: 100%;
    padding: 50px 100px;
    justify-content: space-around;
    column-gap: 30px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      padding: 40px 20px;
    }
  }

  .our-values-accordion-left {
    margin-top: 4em;
    @media (max-width: 768px) {
      margin-top: 2em;
    }
    h3 {
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      font-family: $mainfont;
    }
  }

  .collapse-area {
  }

  .collapse-num {
    color: gray;
    margin-right: 2em;
    @media (max-width: 768px) {
      margin-right: 1em;
    }
  }

  .collapse-optn {
    max-width: 1820px;
    border: none;
    border-bottom: 1px solid lightgray;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .accordion-button {
    font-family: $mainfont;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    padding-bottom: 40px;
    @media (max-width: 768px) {
      font-size: 26px;

    }
  }

  .accordion-button:not(.collapsed) {

  }

  .accordion-button:focus {
    border: none !important;;
    outline: 0 !important;
    box-shadow: none !important;
  }
}