.home-hero-img, .main-home-hero-img {
  //background: url(https://images.unsplash.com/photo-1633910065799-d1d0b3ed96e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1941&q=80);
  //background: url('../Assets/Images/main-common-hero-img.jpg');
  //height: 42em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100vh;
  //height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  //margin-bottom: 100px;
  @media (max-width: 1024px) {
    //height: 16em; //old
    height: 100vh;
  }

  .clicktobottom-animate {
    left: 50%;
    top: 88%;
    position: absolute;
    z-index: 9;
    text-align: center;
    font-size: 12px;
    @media (max-width: 768px) {
      top: 85%;
      left: 46%;
    }

    .indicator {
      position: relative;
      width: 50px;
      height: 50px;

      transform: rotate(45deg);

      span {
        position: absolute;
        left: 0;
        top: 0;
        width: 60%;
        height: 60%;
        box-sizing: border-box;
        border: none;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
        animation: animate 1s linear infinite;
        @media (max-width: 768px) {
          width: 45%;
          height: 45%;
        }

        &:nth-child(1) {
          top: -30px;
          left: -30px;
          animation-delay: 0s;
        }

        &:nth-child(2) {
          top: -15px;
          left: -15px;
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          top: 0;
          left: 0;
          animation-delay: 0.4s;
        }

        &:nth-child(4) {
          top: 15px;
          left: 15px;
          animation-delay: 0.6s;
        }

        &:nth-child(5) {
          top: 30px;
          left: 30px;
          animation-delay: 0.8s;
        }
      }
    }
  }

  @keyframes animate {
    0% {
      border-color: #fff;
      transform: translate(0, 0);
    }
    20% {
      border-color: #fff;
      transform: translate(15px, 15px);
    }
    20.1%, 100% {
      border-color: #98C73D;
    }
  }
}

.homePageVideoHeroSection {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: auto;
  background: rgb(17 17 17 / 50%);

  .homepage_hero_text_title {
    max-width: 787px;
    width: 100%;
    position: absolute;
    top: 30%;
    left: 15%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
      top: 30%;
      left: 8%;
      max-width: 500px;
      width: 100%;

    }
    @media (max-width: 768px) {
      top: 18%;
      left: 3%;
      max-width: 100%;
      padding: 0 30px;
      width: 100%;
      margin: 0 auto;
    }
    @media (max-width: 580px) {
      left: 0;
    }
    @media (max-width: 380px) {
      top: 20%;
    }
  }

  .homepage-vid-hero-inner {
    @media (max-width: 620px) {
      text-align: center;
    }

    .hoome-main-hero-title {
      h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        color: rgba(255, 255, 255, 0.9);
        @media (max-width: 1024px) {
          font-size: 32px;
        }
        @media (max-width: 768px) {
          font-size: 28px;
          font-weight: 600;
        }
      }
    }

    .home-hero-title-caption {
      margin: 1.5em 0 2.5em;
      @media (max-width: 768px) {
        margin: 1.5em 0 2em;
      }

      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: $fontsize_14;
        color: rgba(255, 255, 255, 0.9);
        @media (max-width: 768px) {
          font-size: 14px;
        }
        @media (max-width: 580px) {
          //display: none;
          font-size: 12px;
        }
      }

      @media (max-width: 580px) {
        margin-top: 1.5em;
      }
    }

    .homepage_learnmore_btn {
      background: #98C73D;
      border-radius: 5px;
      border: none;
      padding: 12px 35px;
      color: #FFF;
      font-size: $fontsize_16;
      margin-right: 16px;
      @media (max-width: 580px) {
        padding: 10px 20px;
      }

    }

    .homepage_contact_us_btn {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #FFFFFF;
      border-radius: 5px;
      padding: 10px 35px;
      color: #FFF;
      font-size: $fontsize_16;
      @media (max-width: 580px) {
        padding: 10px 20px;
      }
    }

    .home-hero-on-button {
      @media (max-width: 580px) {
        margin-top: 3em;
      }
    }
  }


}

.homepage-vid-hero {
  margin: auto;
  /* position: absolute; */
  //z-index: -1;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  visibility: visible;
  opacity: 1;
  //width: 100%;
  //height: auto;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  /* background: url(polina.jpg) no-repeat; */
  background-size: cover;

  @media (max-width: 620px) {
    height: 100vh;
    width: auto;
  }
}

.homepage-business-card-header {
  text-align: center;
  margin: 80px 10px;
  @media (max-width: 768px) {
    margin: 55px 10px;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    color: #11274B;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  .border-homepage-business {
    border-bottom: 3px solid #98c73d;
    max-width: 145px;
    margin: 0 auto;
    margin-top: 10px;
  }
}


.itemcards_list {
  .items-card-row {
    //margin: 140px 10px;
  }

  .card-inner {
    max-width: 370px;
    width: 100% !important;
    margin: 0 auto;
    text-align: center;
    //border-radius: 15px;
    position: relative;
    background-color: rgb(0 0 0);
    //border-radius: 1px;
    border: none;
    //box-shadow: 2px 1px 7px 1px #00000024;
    box-shadow: 4px 4px 19px -3px rgb(223 223 223);
    border-radius: 0.5em;
    min-height: 290px;
    height: 100%;

    .card-body-section.card-body {
      a {
        text-decoration: none;
        color: black;
      }

      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;
      margin: 0px;
    }


    .card-image-portfolio {
      opacity: 1;
      display: block;
      width: 100%;
      //height: auto;
      transition: .5s ease;
      backface-visibility: hidden;
      border-radius: 0.5em;
      height: 100%;
    }

    .card-over-content {
      transition: .5s ease;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0%, 0%);
      text-align: center;

      .card-text {
        color: white;
      }

      .card-image-portfolio-icon-hov {
        height: 60px;
        margin-bottom: 1em;
        max-width: 55px;
      }

      .card-over-inner {

        // background-color: rgb(0 0 0);
        // font-size: 16px;
        font-size: $fontsize_14;
        padding: 16px 32px;
        text-align: center;

      }
    }

    .btn-primary-oncard {
      width: 180px;
      height: 50px;
      left: 0px;
      top: 0px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 10px;
      line-height: 4em;
      color: #FFF;
      text-decoration: none;
    }
  }

  .card-inner:hover .card-image-portfolio {
    opacity: 0.3;
  }

  .card-inner:hover .card-body-section {
    display: none;
  }

  .card-inner:hover .card-over-content {
    opacity: 1;
  }


}

#franchcen_main_ads {
  background-image: url('../Assets/Images/RectangleNigtmode.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding: 100px 0;
  @media (max-width: 768px) {
    padding: 50px 10px;
  }
  @media (max-width: 388px) {
    padding: 100px 10px;
  }

  .franchcen_section-article {
    height: 415px;
    max-width: 700px;
    //background: #FFF;
    border-radius: 0 25px 25px 0;
    padding: 50px 50px 0 90px;
    @media (max-width: 768px) {
      text-align: center;
      border-radius: 15px;
      padding: 15px 10px 10px 10px;
      background: rgb(255 255 255 / 67%);
    }

    .franchcen_ads_article_head {
      font-size: $fontsize_32;
      font-weight: 600;
      color: #ffffff;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    .ads_article_text {
      font-size: $fontsize_14;
      color: #ffffff;
      @media (max-width: 768px) {
        color: black;
      }
    }

    .ads_article_btn {
      background-color: #97C63D;
      color: #FFFFFF;
    }

    .ads_article_btn:focus {
      outline: none;
      box-shadow: none;
    }

    @media (min-width: 1080px) {
      max-width: 900px;
    }
    @media (min-width: 2200px) {
      max-width: 1080px;
    }

  }

}

.allcards_country {
  margin-top: 50px;
  width: 100%;
  padding: 10px 35px;
  @media (max-width: 768px) {
    overflow: hidden;
    margin-top: 15px;
  }
  @media (max-width: 490px) {
    padding: 15px 35px;
  }


  h3 {
    text-align: center;
    font-size: $fontsize_32;
    font-weight: 600;
    color: #11274B;
    @media (max-width: 768px) {
      font-size: 24px;
    }

  }

  .allcards_country_inner {
    max-width: 1180px;
    margin: 0 auto;
    width: 100%;
    padding: 0;
    //justify-content: space-around;
    //display: grid;
    //grid-template-columns: repeat(4, 1fr);
    //grid-column-gap: 10px;
    //grid-row-gap: 10px;
    margin-top: 4em;
    @media (max-width: 768px) {
      //grid-template-columns: repeat(1, 1fr);
      margin-top: 2em;
      margin-left: -8px;
    }

    .slick-slider {
      width: 99%;
    }

    .FrancisProjectCountry_main {
      text-align: center;
      align-items: center;
      vertical-align: middle;
      justify-content: center;
      margin: 0;
      cursor: pointer;
      margin-left: 10px;
      margin-right: 10px;

    }

    .FrancisProject_single_card {
      position: relative;
      @media (max-width: 580px) {
        margin-left: 15px;
      }

      h1 {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        text-align: center;
        font-size: 26px;
        background-color: rgb(18 18 18 / 45%);
        //height: 100%;
        width: 100%;
        color: #FFF;
        top: 50%;
        //left: 50%;
        //right: 50%;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }

      .FrProject_card_inner img {
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
      }
    }

    button.slick-arrow.slick-prev:before, .slick-next:before {
      color: #000000;
      width: 30px;
      height: 30px;
      background: rgb(224 224 224 / 55%);
      padding: 10px 10px;
      border-radius: 50%;
      font-size: 15px;
    }
  }

  .all-cards-btns {
    text-align: center;
    margin: 4em 0 0;

    .all-projects-btn {
      background-color: #97C63D;
      color: #FFFFFF;
    }

    .all-projects-btn:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .border-homepage-our-projects {
    border-bottom: 3px solid #98c73d;
    max-width: 145px;
    margin: 0 auto;
    margin-top: 10px;
  }
}

.allcards_country > * {
  flex: 1 1 160px;
}

.homepage-cinemic-video-section {
  margin-top: 100px;
  background-image: url(../Assets/Images/cinemic-group-image.jpg);
  height: 540px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .cinemic-video-inner {
    //max-height: 40em;
    //overflow: hidden;
  }
}

.homepage-cinemic-video-section-old {
  margin-top: 4em;
  overflow: hidden;
}


.HomeDigitalAgency-section {
  //margin-top: 150px;
  margin-bottom: 150px;

  @media (max-width: 1000px) {
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    margin-top: 60px;
  }

  .home-digitalagency-innovate-header {
    text-align: center;
    margin: 80px 10px;
    @media (max-width: 768px) {
      margin: 40px 10px;
    }

    h3 {
      font-size: 32px;
      font-weight: 600;
      color: #11274B;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .HomeDigitalAgency-inner {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;

    .HomeDigitalAgency-left-image {
      text-align: center;
    }

    img.digital-agency-image {
      border-radius: 10px;
      height: auto;
      width: 91%;
    }

    .HomeDigitalAgency-text-header {
      margin-top: 3em;

      h3 {
        font-size: $fontsize_32;
        color: #14142B;
        margin-bottom: 20px;
        font-weight: 600;
        @media (max-width: 768px) {
          font-size: 24px;
        }
      }

      p {
        font-size: $fontsize_14;
        color: #14142B99;
        margin-bottom: 20px;
      }
    }

    .agency-btn-main {
      background-color: #97C63D;
      color: #FFFFFF;
    }

    .agency-btn-main:focus {
      outline: none;
      box-shadow: none;
    }

    .HomeDigitalAgency-right-text {
      @media (max-width: 620px) {
        text-align: center;
      }
    }
  }
  .border-homepage-our-innovation{
    border-bottom: 3px solid #98c73d;
    max-width: 320px;
    margin: 10px auto 0;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.45% !important;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  margin-top: -12em;
  @media (max-width: 1024px) {
    margin-top: unset;
  }
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //top: -14em;
}

.vp-sidedock {
  display: none;
}

.vp-player-ui-overlays {
  display: none !important;
}

.vp-sidedock.SideDock_module_root__aeb51398 {
  display: none !important;
}

.background-image-of-vimeo-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;

  .reactjs-custom-embed-play {
    position: absolute;
    top: 60%;
    background: #030303de;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #98c73d;
    border: 2px solid #98c73d;
    border-radius: 50%;
    padding: 33px 35px;
  }
}

//.player-wrapper {
//  position: relative;
//  padding-top: 46.25% /* Player ratio: 100 / (1280 / 720) */
//}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}