.sustainability-hero-img{
  //background: url(https://images.unsplash.com/photo-1633910065799-d1d0b3ed96e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1941&q=80);
  background: url('../Assets/Images/Sustainability/sustainability-hero-img-img.jpg');
  //height: 42em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100vh;
  //height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  //margin-bottom: 100px;
  @media (max-width: 1024px) {
    //height: 16em;
    height: 100vh;
  }
}

.sus-commitment-main{
  padding: 100px 0;
  @media (max-width: 1024px) {
    padding: 30px 0;
  }
  .sus-commitment-inner {
    display: flex;
    align-items: center;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    column-gap: 100px;
    justify-content: space-around;
    padding: 0 50px;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      //flex-direction: column-reverse;
      text-align: center;
      padding: 0 25px;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      text-align: center;
      padding: 0 20px;
    }
    @media (max-width: 580px) {
      flex-wrap: wrap;
      text-align: center;
      padding: 0 10px;
    }

    .sus-commitment-left {
      max-width: 550px;
      @media (max-width: 1024px) {
       max-width: 700px;
      }
      .sus-commitment-txt-title {
        font-size: 40px;
        font-weight: 600;
        font-family: $mainfont;
      }
      .sus-commitment-txt{
      font-size: $fontsize_16;
        font-weight: 400;
        font-family: $mainfont;
        margin-top: 30px;
      }
    }
    .sus-sus-commitment-right{
      max-width: 550px;
      img{
        @media (max-width: 1124px) {
          max-width: 400px;
        }
        @media (max-width: 1024px) {
          max-width: 700px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

}

.sus-centurionway-section {
  background: #F8F8F8;
  .sus-centurionway-header{
    text-align: center;
    margin-bottom: 50px;
    h3 {
      font-size: 40px;
      font-weight: 600;
      font-family: $mainfont;
    }

    p{
      font-size: 16px;
      font-weight: 400;
      font-family: $mainfont;
    }
  }
}
.sus-centurionway-inner {
  padding: 100px 0 60px;
  @media (max-width: 768px) {
    padding: 40px 0 30px;
  }
}
.sus-portfolio-info-cen-way {
  display: flex;
  justify-content: space-around;
  padding: 0 50px;
  margin: 0 auto;
  text-align: center;
  flex-wrap: wrap;
  max-width: 1300px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  .sus-cen-card-img-description p {
    font-size: 16px;
    font-weight: 400;
    font-family: $mainfont;
  }
  .sus-portfolio-info-data {
    max-width: 330px !important;
    width: 100% !important;
    column-gap: 15px;
    margin-bottom: 30px;
  }
}
.sus-toward-health-section {
  //text-align: center;
  padding: 0 50px;
  @media (max-width: 1200px) {
    padding: 0 30px;
  }
  @media (max-width: 768px) {
    padding: 0 15px;
  }
  .sus-health-section-inner{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 100px;
    padding: 100px 0;
    @media (max-width: 1200px) {
      column-gap: 50px;
      padding: 70px 0;
    }

    @media (max-width: 768px) {
     column-gap: 25px;
      flex-wrap: wrap;
      padding: 40px 0;
    }
    .sus-health-left {
      max-width: 425px;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 700px;
      }
      h3{
        font-size: 40px;
        font-weight: 600;
        font-family: $mainfont;
      }
    }
    .sus-health-right{
      max-width: 650px;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 700px;
      }
      .health-fisttext{
        font-size: $fontsize_16;
        font-family: $mainfont;
        font-weight: 400;
      }
      .health-second{
        font-size: 16px;
        font-family: $mainfont;
        font-weight: 300;
        font-style: italic;
      }
    }
  }
}