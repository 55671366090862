
.contact-hero-img{
    //background: url(https://images.unsplash.com/photo-1633910065799-d1d0b3ed96e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1941&q=80);
    //background: url('../Assets/Images/ContactUs/contact-works-office.jpg');
    //height: 42em;
    background-color: black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    height: 10vh;
    //height: 70vh;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    min-height: 70px;
    //margin-bottom: 100px;
    @media (max-width: 1024px) {
        //height: 16em;
        height: 10vh;
    }
}

// Contact Title Section code starts
.contactTitleSec{
    text-align: center;
    margin-top: 100px;
    h3{
        font-size: $fontsize_60;
        color: #11274B;
    }
    p{
        font-size: $fontsize_16;
        color: #14142B;
    }
    
}
.contactFormSec{
    margin-top: 50px;
    margin-bottom: 50px;

    // @media (max-width: 992px) {
    //     flex-wrap: wrap !important;
    // }
}

// Contact Title Section code ends

// Contact Form Section code starts

.contactFormArea{
    max-width: 90%;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgba(22, 0, 86, 0.08);
    border-radius: 10px;
    @media (max-width: 690px) {
        max-width: 100%;
        margin: 0 auto;
    }

    h3{
        // text-align: left;
        font-size: $fontsize_40;
        padding-left: 20px;
        padding-top: 50px;
        margin-bottom: 30px;
        color: #11274BCC;

    }

    .formInputArea{
        max-width: 100%;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 30px;
        h4{
            font-size: $fontsize_16;
        }
        .button-glow{
            background-color: #98C73D;
            align-items: center;
            width: 100%;
            border-color: white;
        }
        
    }
    .input-design{
        width: 100%;
        height: 50px;
        border: 1px solid #D8DAE14D;
        background-color: #F9FCFF;
        border-radius: 10px;
        padding-left: 20px;
    }
    .textarea-design{
        width: 100%;
        border: 1px solid #D8DAE14D;
        background-color: #F9FCFF;
        border-radius: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    
}

// Contact form section code ends here

// Contact Address Section code start from here 

.contactAddressArea{
    
}


// General Inquiries section 
.generalInquiries{
    // max-width: 1280px;
    // margin: 0 auto;
    // padding: 0 30px;

    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgba(22, 0, 86, 0.08);
    border-radius: 10px;
    

    h5{
        padding-left: 20px;
        padding-top: 50px;
        margin-bottom: 30px;
        color: #11274BCC;
        font-size: $fontsize_20;
    }
    .phoneContent,
    .emailContent{
        padding-left: 20px;
    }
    h6{
        padding-left: 5px;
        color: rgba(17, 39, 75, 0.8);
        font-size: $fontsize_16;
        
    }
    .iconContent{
        padding: 40px;
        justify-content: space-between;
    }
}

//  Office Address section
.officeAddress{
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 5px 10px 30px rgba(22, 0, 86, 0.08);
    border-radius: 10px;
    margin-top: 50px;
    h5{
        padding-left: 20px;
        padding-top: 50px;
        margin-bottom: 30px;
        color: #11274B;
        font-size: $fontsize_20;
    }
    h6{
        padding-left: 20px;
        color: rgba(17, 39, 75, 0.8);
        padding-bottom: 30px;
        font-size: $fontsize_16;
    }

}

// Google Map Section 
.googleMapArea {
    max-width: 100%;
    margin-top: 50px;

    .map-area{
        width:100%;
        height: 20rem;
        border-radius: 10px;
    }
}



