.our-business-hero-img {
  //background: url(https://images.unsplash.com/photo-1633910065799-d1d0b3ed96e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1941&q=80);
  background: url('../Assets/Images/main-common-hero-img.jpg');
  //height: 42em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100vh;
  //height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  //margin-bottom: 100px;
  @media (max-width: 1024px) {
    //height: 16em;
    height: 100vh;
  }
}

.OurbusinessCard-header {
  text-align: center;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  margin-top: 5em;
  margin-bottom: 5em;

  .OurbusinessCard-headertexts {
    width: 70%;
    margin: 0 auto;
  }
}

.business_allcards_inner {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;

  .OurbusinessCard-section {
    max-width: 350px;
    position: relative;
    margin-bottom: 15.5em;

  }

  .OurbusinessCard-inner {
    .single-imgs-b {
      border-radius: 10px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .OurbusinessCards-on-card {
    position: absolute;
    width: 350px;
    height: 223px;
    left: 2em;
    top: 13em;
    background: #FFFFFF;
    border-radius: 10px 0px 10px 10px;
    padding: 40px;
    box-shadow: 5px 10px 30px rgb(22 0 86 / 8%);

    #link-line {
      text-decoration: none;
    }

    &:hover {
      color: #b5e45a;
    }

    .OurbusinessCard-learnmore {
      h3 {
        font-family: $mainfont;
        font-style: normal;
        font-weight: 500;
        font-size: $fontsize_20;
        line-height: 30px;
        color: #98C73D;
      }
    }
  }

  .Ourbusiness-card-header-description {
    border-top: 1px solid;
    padding: 5px;
    border-style: dashed;
  }
}