.AgrifoodsHeaderTopCard-header {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  text-align: center;
  margin-top: 140px;
}

.agrifoodportfilio-main {
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  justify-content: space-around;
  margin-top: 60px;
  @media (max-width: 680px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;

    row-gap: 70px;

  }

  .agrifoodportfiliosection-left {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 680px) {
      width: 100%;
    }

    .agrifood-portfolio-lists-main {
      display: flex;

      .agrifood-plant-option-img {
        margin-right: 2em;
      }
    }
  }

  .AgrifoodPortfilioSection-right {
    width: 45%;
    @media (max-width: 680px) {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }
}

.agrifood-video-section-main {
  margin-top: 140px;

  img {
    width: 100%;
  }
}

.why-chooseus-main {
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  justify-content: space-around;
  margin-top: 140px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }

  .why-choose-us-left-title {
    width: 50%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0 auto;
      align-items: center;
    }

    p {
      max-width: 390px;
      @media (max-width: 768px) {
        text-align: center;
      }
    }

  }

  .why-choose-us-right-client-portal {
    width: 50%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    justify-content: center;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0 auto;
      align-items: center;
    }

    .why-choose-us-card {
      background: white;
      margin: 20px;
      text-align: center;
      height: 180px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 20px -12px;
      border-radius: 5px;

      img {
        background: #98C73D;
        padding: 20px;
        border-radius: 50%;
        height: 88px;
        width: 88px;
      }
    }
  }
}