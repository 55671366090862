// DedicatedSuccess component code starts with responsive
$fontsize_16: 16px;
$fontsize_14: 14px;

.dedicatedSuccessSec{
    margin-top: 150px;
    margin-bottom: 150px;

    .dedicatedSuccessInner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
        @media (max-width: 1200px) {
            .dedicatedSuccessImg{
                max-width: 100%;
            }
        }
    }
}

.dedicatedSuccessText{
    .corporateIconSide{
        display: flex;
        
    }
    .dedicatedSuccessTitle{
        // font-size: $fontsize_48;
        font-size: 48px;
        color: #14142B;
    
    }
    .dedicatedSuccessDescribe{
        // font-size: $fontsize_20;
        font-size: $fontsize_16;
        color: #14142B;
        margin-left: 1rem;
    }
    img{
        height: 40px;
        width: 50px;
        margin-bottom: 1em;
    }
    
}

// DedicatedSuccess component code ends

// ReviewsCard component code starts with responsive

.ReviewsArea{
    background-color: #F8F8F8;
    padding: 150px 0px;

    .ReviewsTagLine{
        text-align: center;
        h3{
            font-size: 40px; 
        }
        p{
            font-size: 16px;
        }
    }

    .reviewAllCards{
        // margin: 20px;
        max-width: 90%;
        // border: 1px solid black;
        // background: #584545;
        // box-shadow: 5px 10px 30px rgba(10, 9, 15, 0.08);
        
    }

    .reviewCardBody{
        // column-gap: 10px !important;
        
        border-radius: 10px;
        padding: 20px;
        height: 400px;

        background: #FFFFFF;
        box-shadow: 5px 10px 10px rgb(45 35 50 / 5%);

        img{
            margin-bottom: 20px;
            margin-top: 5px;
        }
        p{
            height: 200px;
            color: #14142B;
            border-bottom: 1px solid #14142B;

            @media (max-width: 1400px) {
                height: 230px;
            }
            @media (max-width: 1200px) {
                height: 275px;
            }
        }
        .cardFooter{
            display: flex;
            max-width: 100%;
            margin-top: 20px;
            
            img{
                height: 60px;
                width: 60px;
            }
        }
        .footerText{
            padding: 5px;

            h4{
                font-size: 16px;
                color: #14142B;
            }
            p{
                font-size: 12px;
                color: #14142B;
            }
        }

        @media (max-width: 1400px) {
            height: 450px;
        }

        
    }
}

// Review Card component code ends

// WorkingWithUs component code starts

.workWithSection{
    margin-top: 150px;
    margin-bottom: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    .workWithTitle{
        font-size: 40px;
    }
    
    @media (max-width: 768px) {
        flex-wrap: wrap;
        .workWithTitle{
            margin-bottom: 20px;
        }
    }
    @media (max-width: 1200px) {
        max-width: 100%;
    }
    
}
.WhyWorkingText{
    h5{
        font-size: $fontsize_16;
        color: #14142B;
        margin: 12px 0;
    }
    p{
        font-size: 16px;
        color: #14142B;
    }
}

