.hero-main-title-page {
  color: #FFFFFF;
  position: absolute;
  left: 23%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  z-index: 9;
  text-align: center;

  h2 {
    font-size: 50px;
    font-weight: 700;
    font-family: $mainfont;
    text-align: center;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 28px;
      font-weight: 500;
    }
  }

  p {
    font-family: $mainfont;
    font-style: normal;
    font-weight: 500;
    font-size: $fontsize_16;
    @media (max-width: 768px) {
      font-size: $fontsize_14;
    }
    span{
      color: #808080;
      padding-right: 10px;
    }
  }
}