.location-hero-img {
  //background: url(https://images.unsplash.com/photo-1633910065799-d1d0b3ed96e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1941&q=80);
  background: url('../Assets/Images/Locations/technology-3435575_1280.jpg');
  //height: 42em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100vh;
  //height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  //margin-bottom: 100px;
  @media (max-width: 1024px) {
    //height: 16em;
    height: 100vh;
  }
}


.location-main-ReviewsArea {
  @media (max-width: 768px) {
    max-width: 700px;
    margin: 0 auto;
  }
  @media (max-width: 620px) {
    max-width: 320px;
  }

  .slick-slider {
    .slick-prev:before, .slick-next:before {
      color: red !important;
    }
  }

  /* the slides */
  .slick-slide {
    img {
      max-width: 255px;
      margin: 0 auto;
      //cursor: pointer;
    }
  }

  .slick-list {
    .slick-track {
      height: 340px;
    }
  }

  .location-card-img-main {
    text-align: center;
    justify-content: center;
    margin: 0;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  }

  .slide-margin-top {

    margin-bottom: -20px;
  }

  .slide-margin-bottom {
    margin-top: -20px;
  }

}

.location-name-details-texts-main {
  background: #F8F8F8;
  margin-bottom: 120px;

  h3 {
    padding-top: 10px;
    margin-top: 3em;
    margin-bottom: 3em;
    text-align: center;
  }

  .location-covered-photo-top {
    text-align: center;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;

    img {
      width: 100%;
      max-height: 500px;
      border-radius: 20px;
    }

  }
  .ajax-loading{
    background:transparent url(https://cdn-ap-ec.yottaa.net/58a4671832f01c09d60000d0/business.simon.com/v~4b.cc/siteassets/images/ajax-loader-cs.gif) center center no-repeat;
    margin: 0 auto;
    text-align: center;
    min-height: 100px;
    max-height: 500px;
    height: auto;
    //height: 100px;
  }

  .location-address-photo {
    display: flex;
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
    padding: 20px 40px;
    column-gap: 100px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 770px) {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 70px;
    }
  }

  .location-adress-inner-left {
    .location-adress-inner-lft-heder {
      h4 {
        font-size: 30px;
        font-weight: 700;
      }
    }
  }

  .location-inner-right {
    img {
      width: 100%;
      max-width: 400px;
      min-width: 400px;
      @media (max-width: 520px) {
        min-width: 280px;
      }
    }
  }

  .location-street-addr {
    max-width: 150px;

    p {
      line-height: 30px;
    }
  }

  .location-addr-social-icon {
    display: flex;
    max-width: 300px;
    justify-content: space-around;
    align-items: center;
    padding: 0;

    .lists-of-location-social-icon {
      background: rgba(151, 198, 61, 0.1);
      padding: 8px 13px;
      border-radius: 50%;
      width: 40px;
      height: 40px;

      a {
        color: #27920f;
      }
    }

    .lists-of-location-social-icon:hover {
      background: #c2edb6;
    }
    A[href=""], A[href="#"] {
      display: none;
    }
    a:not([href]):not([class]), a:not([href]):not([class]):hover {
      color: inherit;
      text-decoration: none;
      display: none;
    }
  }

  .location-addr-left-explore-btn {
    margin-top: 3em;

    .location-addr-left-explore-url {
      background: #97C63D;
      border-radius: 5px;
      border: none;
      padding: 10px 50px;
      color: white;
      font-size: $fontsize_16;
      font-weight: 500;
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
    }
  }
}