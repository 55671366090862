$fontsize_16: 16px;
$fontsize_14: 14px;
$fontsize_32: 32px;
.about-hero-img {
  //background: url(https://images.unsplash.com/photo-1633910065799-d1d0b3ed96e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1941&q=80);
  background: url('../Assets/Images/About/christina-about-us-coverd.jpg');
  //height: 42em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100vh;
  //height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  //margin-bottom: 100px;
  @media (max-width: 1024px) {
    //height: 16em;
    height: 100vh;
  }
}

.about-who-we-are-main {
  padding: 10px 50px;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }

  button {
    background: #97C63D;
    border-radius: 5px;
    border: none;
    padding: 10px 50px;
    color: white;
    font-size: $fontsize_16;
    font-weight: 500;
  }

  .about-who-we-are-inner {
    display: flex;
    max-width: 1180px;
    justify-content: space-around;
    align-items: center;
    margin: 45px auto;
    column-gap: 100px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
      text-align: center;
      flex-direction: column-reverse;
      margin: 80px auto;
    }

    .about-whowe-are-left {
      max-width: 50%;
      @media (max-width: 768px) {
        margin-top: 3em;
        max-width: 100%;
      }
    }

    .about-who-we-are-title {
      h2 {
        color: #14142B;
        font-size: 40px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
      }
    }

    .about-who-we-are-texts {
      p {
        font-size: $fontsize_16;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
      }
    }
  }

  .about-whowe-are-rightimg {
    img {
      width: 100%;
    }
  }
}

.maparea-main {
  text-align: center;
  padding: 0 1em 5em;

  .map-area-titletext {
    padding: 15px 10px 40px;
  }

  .mapof-vector {
    display: flex;
    justify-content: space-around;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 30px;
    align-items: center;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    svg {
      height: 600px;
      width: 600px;
      margin-left: -10em;
      @media (max-width: 768px) {
        height: 300px;
        width: 300px;
        margin-left: unset;
        left: unset !important;
      }
    }

    path {
      stroke: #98c73d !important;
      fill: #11274B !important;
      cursor: pointer;
    }

    path:hover {
      fill: #2973ce !important;
    }
  }

  .mapTooltip {
    text-align: left;
    .the-clickable-map-country-data {
      text-align: left;
      .the-data-table {
        margin: 1px 0px 5px;
        box-shadow: 1px 14px 55px -14px #c3bfbf;
        padding: 10px;
      }
    }
  }
}

.about-our-ceo-main {
  background: #3A3F52;
  background: url(https://dangote.com/wp-content/uploads/2020/12/gpce_grey2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 200px 0;
  @media (max-width: 768px) {
    padding: 70px 0;
  }

  .about-our-ceo-inner {
    display: flex;
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
    column-gap: 30px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .about-our-ceo-left {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .about-our-ceo-right-text {
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .about-our-ceo-right-text {
    padding: 10px 30px;

  }

  .about-our-ceo-right-txt-inner {
    .about-our-ceo-title {
      h2 {
        color: #FFFFFF;
        font-size: 40px;
        font-weight: 600;
        font-family: "Poppins", sans-serif;
      }
    }

    .about-our-ceo-texts {
      p {
        color: #FFFFFF;
        font-size: $fontsize_16;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }
    }

    .about-our-ceo-btn {
      button {
        background: #97C63D;
        border-radius: 5px;
        border: none;
        padding: 10px 50px;
        color: white;
        font-size: $fontsize_16;
        font-weight: 500;
      }
    }
  }
}


.about-our-leadership-main {
  padding: 20px 30px;

  .our-leadership-title {
    margin: 3em;

    h2 {
      font-size: $fontsize_32;
      text-align: center;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
  }

  .our-leadership-sub-txt {
    max-width: 700px;
    width: 100%;
    margin: 0 auto 2em;

    p {
      text-align: center;
    }
  }

  .about-executive-leadership-lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1180px;
    margin: 0 auto;

    .about-executive-single-card {
      width: 100%;
      text-align: center;
      margin: 10px 0 0 2%;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: calc(22% - 1em);
    }

    .about-executive-card-img {
      position: relative;
      width: 100%;
      max-width: 270px;

      .image-of-about-executive {
        display: block;
        height: auto;
      }

      .overlay-about-executive-txt {
        position: absolute;
        bottom: 0;
        background: rgb(152 199 61);
        width: 100%;
        transition: .5s ease;
        height: 100%;
        opacity: 0;
        color: white;
        font-size: $fontsize_16;
        padding: 20px;
        text-align: center;
        z-index: 9;
      }

      .executive-name-over-title {
        font-size: 18px;

      }

      .executive-name-of-role {
        font-size: 14px;

      }

      .executive-bio-card-over {
        font-size: 14px;
      }
    }

    .about-executive-card-img:hover .overlay-about-executive-txt {
      opacity: 1;
    }

    .about-executive-img-title {
      .about-executive-name-card {

      }

      .about-executive-sub-txt {

      }
    }
  }
}