.media-hero-img {
  //background: url(https://images.unsplash.com/photo-1633910065799-d1d0b3ed96e8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1941&q=80);
  background: url('../Assets/Images/Media/media-page-coverd-photo.jpg');
  //height: 42em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100vh;
  //height: 70vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  //margin-bottom: 100px;
  @media (max-width: 1024px) {
    //height: 16em;
    height: 100vh;
  }
}

// Latest News Section code start from here
.latestNewsSection {
  background-color: #FFFFFF;
  margin-bottom: 100px;
}

.latestNewsTagSection {
  // align-items: center;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 50px;

  h3 {
    font-size: 40px;
    color: #11274B;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    color: #14142B;
    font-weight: 400;
    max-width: 560px;
    margin: 0 auto;
  }

  .border-media-our-news {
    border-bottom: 3px solid #98c73d;
    max-width: 350px;
    margin: 10px auto 0;
  }
}

// News card design
.news-item {
  // max-width: 100%;
  background: #FFFFFF;
  box-shadow: 5px 10px 30px rgba(22, 0, 86, 0.08);
  border-radius: 10px;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 5px;

  #link-line {
    text-decoration: unset;
  }

  .posted-person,
  .news-headline,
  .news-description,
  .new-readMore {
    padding: 0 25px;
  }

}

.news-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .news-date {
    height: 25px;
    width: 35%;
    background-color: #98C73D;
    margin: -25px 0 10px 0px;
    border-radius: 10px 0 0 0;

    p {
      font-size: 16px;
      color: #FFFF;
      text-align: center;
    }

    @media (max-width: 1399px) {
      width: 45%;
    }
    @media (max-width: 991px) {
      width: 65%;
    }
    @media (max-width: 767px) {
      width: 35%;
    }
  }

}

.posted-person {
  color: #98C73D;
}

.news-headline {
  height: 50px;

  h3 {
    font-size: 16px;
    color: #11274B;
  }

  @media (max-width: 1399px) {
    height: 55px;
  }
  @media (max-width: 1199px) {
    height: 65px;
  }
  @media (max-width: 991px) {
    height: 110px;
  }
  @media (max-width: 767px) {
    height: 50px;
  }
  @media (max-width: 425px) {
    height: 70px;
  }
}

.news-description {
  height: 70px;

  p {
    color: #11274B;
    font-size: 14px;
  }

  @media (max-width: 1399px) {
    height: 85px;
  }
  @media (max-width: 1199px) {
    height: 100px;
  }
  @media (max-width: 991px) {
    height: 110px;
  }
  @media (max-width: 767px) {
    height: 70px;
  }
  @media (max-width: 425px) {
    height: 120px;
  }
}

.new-readMore {
  margin-top: 15px;
  margin-bottom: 15px;

  h3 {
    margin-top: -15px;
    font-size: 20px;
    color: #98C73D;
    // border-bottom: 1px solid #ffffff;

  }

  // h3:hover{
  //     border-bottom: 1px solid #98C73D;
  //     width: 100px;
  //     transition: .5s;
  // }
  .readMore-underline {
    margin-top: -30px;
    width: 13px;
    border-bottom: 1px solid #98C73D;

    height: 25px;
    background-clip: content-box;

  }

  .readMore-underline:hover {
    width: 100px;
    transition: .5s;
  }

  @media (max-width: 1399px) {
    margin-top: 25px;
  }
  @media (max-width: 1199px) {
    margin-top: 40px;
  }
  @media (max-width: 991px) {
    margin-top: 55px;
  }
  @media (max-width: 767px) {
    margin-top: 15px;
  }

}


// Media Gallery section code start from here

.gallerySection {
  background-color: #F8F8F8;
  padding-top: 50px;
  padding-bottom: 30px;
  margin-bottom: 100px;
}

.media-gallery {
  h1 {
    text-align: center;
    color: #11274B;
    font-size: 40px;
    margin-top: 40px;
  }
}

.gallery-link-section {
  text-align: center;
  margin: 15px 0px;

  nav {
    margin: 0 auto;
    max-width: 40%;
    display: flex;
    justify-content: space-around;

    button {
      border: none;
      background-color: transparent;
      font-weight: 500;
    }
  }
}

.image-section {
  margin-bottom: 100px;

}

// Specific Latest News page

.newsArea-singlePage {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 50px;

  .newsImage {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;

    img {
      margin: 50px auto;
      max-width: 1200px;
      width: 100%;
      border-radius: 10px;
    }

  }

  .newsPosted {
    h6 {
      color: gray;
    }
  }

  .newsTitle {
    h5 {
      font-size: 40px;
      margin-bottom: 25px;
      @media(max-width: 768px) {
        font-size: 25px;
      }
    }

  }

  .newsDescription {
    p {
      font-size: 17px;
    }

  }
}



