#cen-mainfooter {
  // background: #000026;
  // position: relative;
  padding: 12em 0 0;
  @media (max-width: 768px) {
    padding: 7em 0 0;
  }

  .cen-footer-content {
    background: #000026;
    // max-width: 1920px;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
    padding: 30px;
    padding-top: 10em;
    .jump-to-top-icon{
      position: fixed;
      bottom: 1em;
      right: 1em;
      text-align: center;
      color: #98c73d;
      cursor: pointer;
      font-size: 12px;
      line-height: 32px;
      img {
        width: 50%;
      }
    }
  }

  .cen-footer-items {
    display: flex;
    justify-content: space-between;

    .site_logo_text {
      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 2em;
        margin-top: 2em;
        color: rgba(255, 255, 255, 0.6);
      }
      .footer-social-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 260px;
        padding: 10px 10px;
        margin: 0 auto 0 0;
        .lists-of-footer-social-icon{
          border: 1px solid #fff;
          padding: 8px 12px;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          a{    color: #FFFFFF;
            font-size: 16px;
          }
        }
      }
    }
    .footer-app-download-icon{
      img{
        max-width: 240px;
        width: 100%;
        height: 75px;
        margin-top: 2em;
        margin-left: 8px;
      }
    }

    .cen-footer-single-inn {
      a {
        font-family: $mainfont;
        font-style: normal;
        font-weight: 400;
        font-size: $fontsize_16;
        line-height: 2.5em;
        color: rgba(255, 255, 255, 0.7);
        text-decoration: none;
      }

      a:hover {
        color: #fbfbfb;
        text-decoration: none;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      row-gap: 3em;
    }
  }

  .cen-footer-bottom-single {
    .cen-footer-single-inn {
      display: flex;
      flex-direction: column;
    }
  }

  .cen-footer-item-logo {
    max-width: 300px;
    width: 100%;
  }

  .cen-footer-bottom {
    max-width: 1180px;
    margin: 0 auto;

    .site_logo_image {
      img {
        max-width: 250px;
      }
    }

    .cen-footer-menu-title h4 {
      font-size: $fontsize_20;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      color: $solidwhite;
      font-family: $mainfont;
    }
  }


  .footer-blank-top-spaces {
    margin: auto;
    position: relative;
    // top: 0;
    top: -10em;
    left: 0;
    bottom: 0;
    right: 0;
    width: 65%;
    @media (max-width: 768px) {
      width: 98%;
    }

    .footer-top-subscribe-mail {
      position: absolute;
      margin: 0 auto;
      background-image: url(../Assets/Images/footer-subscribe-Vector.png);
      max-width: 1170px;
      width: 100%;
      text-align: center;
      background-color: #98C73D;
      padding: 60px 0;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 5px;
      @media (max-width: 768px){
        padding: 20px 0;
      }
      .subscribe-mail-header {
        h2 {
          color: #FFF;
          font-family: $mainfont;
          font-size: 40px;
          @media (max-width: 768px) {
            font-size: 26px;
          }
        }
        p{
          color: #FFF;
          font-family: $mainfont;
          font-size: $fontsize_14;
          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }
    .footer-subsscibe-mail-box {
      input {
        border: 0;
        padding: 20px 230px 20px 27px;
        border-radius: 8px;
        text-align: left;
        @media (max-width: 768px) {
          padding: 18px 150px 18px 10px;
        }
        @media (max-width: 568px) {
          padding: 18px 80px 18px 10px;
        }
      }
      button {
        border: 0;
        margin-left: -160px;
        font-size: 18px;
        background: #9CC944;
        color: #FFF;
        padding: 10px;
        border-radius: 5px;
        @media (max-width:580px) {
          margin-left: -106px;
          font-size: 12px;
          padding: 8px;
        }
      }
    }

  }

  .cen-footer-copyright {
    background: #000026;
    padding: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    // margin-bottom: 1em;
    .cen-footer-copyright-content {
      color: #fff;
      text-align: center;
      font-size: $fontsize_14;
      font-family: $mainfont;
    }
  }

}