#nav_site_header {
  // background-color: black !important;
  background-size: cover !important;
  background-image: none !important;
  position: fixed;
  width: 100%;
  z-index: 99;

  .header-nav-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 12px 30px;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1024px) {
      position: relative;
    }
    .custom-select-lang{
      background: transparent;
      border: transparent;
      //border-bottom: solid 2px white;
      width: 40px !important;
      color: #f2fbe3;
      height: 30px;
      margin-top: 5px;
      margin-left: 1em;
      border-radius: 10px;;
      option {
        background: #373636;
      }
    }

    .vodiapicker{
      display: none;
    }

    #a{
      padding-left: 0px;
    }

    #a img, .btn-select img{
      width: 12px;

    }

    #a li{
      list-style: none;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    #a li:hover{
      background-color: #F4F3F3;
    }

    #a li img{
      margin: 5px;
    }

    #a li span, .btn-select li span{
      margin-left: 30px;
    }

    /* item list */

    .b{
      display: none;
      width: 100%;
      max-width: 350px;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      border: 1px solid rgba(0,0,0,.15);
      border-radius: 5px;

    }

    .open{
      display: show !important;
    }

    .btn-select{
      margin-top: 10px;
      width: 100%;
      max-width: 350px;
      height: 34px;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #ccc;

    }
    .btn-select li{
      list-style: none;
      float: left;
      padding-bottom: 0px;
    }

    .btn-select:hover li{
      margin-left: 0px;
    }

    .btn-select:hover{
      background-color: #F4F3F3;
      border: 1px solid transparent;
      box-shadow: inset 0 0px 0px 1px #ccc;


    }

    .btn-select:focus{
      outline:none;
    }

    .lang-select{
      margin-left: 50px;
    }

  }

  .site_logo_main {
    img {
      max-width: 190px;
      width: 100%;
      max-height: 700px;
      height: auto;
      @media (max-width: 768px) {
        max-width: 140px;
      }
    }
  }

  .nav-link-unorder {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
      max-width: 1020px;
      flex-direction: column;
      width: 100%;
      min-width: 300px;
      text-align: center;
      //background: #97c640;
      position: absolute;
      //right: 10px;
      right: 0px;
      top: 4em;
      z-index: 3;
      display: none;
      border-radius: 10px;
      background: black;
    }

    .nav-link-unorder:hover {
      color: rgb(128, 113, 113);
      text-decoration: underline;
      // border-bottom: 5px salmon;
    }

    .menu_single_item {
      padding: 10px;
      color: #ded5d5;
      text-decoration: none;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: $fontsize_14;
      line-height: 24px;
      position: relative;
      max-height: 32px;
      @media (max-width: 1024px) {
        max-height: unset;
      }
    }

    .menu_single_item:hover {
      color: $solidwhite;
      font-size: 15px;
      //text-decoration: underline;
      // border-bottom: 5px salmon;
    }
    #menu_items_business{
      width: 155px;
      @media (max-width: 1024px) {
        width: unset;
      }
    }

    .menu_single_contact {
      //background: #80B7D8;
      border-radius: 9px;
      height: 35px;
      margin-top: 5px;
      padding: 5px 15px;
    }
  }


  .menu-icon-menu {
    display: none;
    @media (max-width: 1024px) {
      display: block;
      border: 2px solid #5fca1a;
      padding: 4px 11px;
      color: white;
      border-radius: 5px;
      font-size: 14px;
    }
  }


  .drop-down-parent, .menu_single_item_child_location {
    display: none;
    @media (max-width: 1024px) {
      display: flex !important;
      width: 90%;
      margin: 0 auto;
    }
  }
  .dropdown-items{
    position: relative;
    width: 400px;
    background: white;
    height: 50vh;
    max-height: 180px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px 10px 2px 10px;
    //padding: 12px;
    margin: 0 auto;
    justify-content: flex-start;
    top: 2em;
    box-shadow: 1px 5px 13px -8px #2d2d2d;
    padding: 18px 32px;

    @media (max-width: 1024px) {
      //old display flex style start
                //position: relative;
                //width: unset;
                //background: unset;
                //max-height: unset;
                //height: unset;
                //display: flex;
                //flex-wrap: wrap;
                //border-radius: 2px 10px 2px 10px;
                //margin: 0 auto;
                //box-shadow: none;
                //padding: 10px;
      //old display flex style end

      background: unset;
      border-radius: 2px 10px 2px 10px;
      box-shadow: none;
      display: flex;
      flex-flow: column;
      width: 400px;
      right: -11em;
      border: 2px solid #ffff;
      border-top: 0;
      border-bottom: 0;
      border-right: 0;
      top: 10px;
      position: relative;
      flex-wrap: wrap;
      height: unset;
      margin: 0 auto;
      max-height: unset;
      padding: 10px;
    }
  }
  .dropdown-items::before{
    content: "";
    color: #2a2626;
    position: absolute;
    top: -15px;
    font-size: 34px;
    transform: rotate(-1deg);
    left: 30px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 20px solid #ffffff;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .menu_single_item_child, .menu_single_item_child_location {
    color: #585454;
    text-decoration: none;
    width: 48%;
    height: 58px;
    text-align: left;
    left: 1em;
    top: 2.2em;
    font-weight: 500;
    font-family: 'Poppins';
    @media (max-width: 1024px) {
      color: #f0f0f0;
      height: 38px;
    }
  }
  .menu_single_item_child:hover {
    font-size: 16px;
  }

}

.scrolled {
  //background: #1212129c !important;
  background-color:rgb(0 0 38 / 75%) !important;
}